import { default as aboutKJAmqyq7bDMeta } from "/usr/src/app/pages/about.vue?macro=true";
import { default as _91sessionId_93xlb4grVyGrMeta } from "/usr/src/app/pages/administration/chats/[sessionId].vue?macro=true";
import { default as indexWiQLyUGRtPMeta } from "/usr/src/app/pages/administration/chats/index.vue?macro=true";
import { default as _91orderId_9312DDsEeZU3Meta } from "/usr/src/app/pages/administration/disputes/[orderId].vue?macro=true";
import { default as index5EXV895ZYhMeta } from "/usr/src/app/pages/administration/disputes/index.vue?macro=true";
import { default as _91requestId_934gcQDZFwBdMeta } from "/usr/src/app/pages/administration/exchange/requests/[requestId].vue?macro=true";
import { default as index3O3BoIF0fgMeta } from "/usr/src/app/pages/administration/exchange/requests/index.vue?macro=true";
import { default as setupg73Ny455liMeta } from "/usr/src/app/pages/administration/exchange/setup.vue?macro=true";
import { default as exchangeU3X7Mdu0dtMeta } from "/usr/src/app/pages/administration/exchange.vue?macro=true";
import { default as indexnMNUgWRJc2Meta } from "/usr/src/app/pages/administration/index.vue?macro=true";
import { default as statsTyV4bWlsd2Meta } from "/usr/src/app/pages/administration/stats.vue?macro=true";
import { default as indexJlUehoice6Meta } from "/usr/src/app/pages/administration/users/index.vue?macro=true";
import { default as administrationDGZ6pUCGuuMeta } from "/usr/src/app/pages/administration.vue?macro=true";
import { default as alm_45ctf_45kycHv7B8bdSXDMeta } from "/usr/src/app/pages/alm-ctf-kyc.vue?macro=true";
import { default as beginners_45guideXY0ITcISlEMeta } from "/usr/src/app/pages/beginners-guide.vue?macro=true";
import { default as _91codeId_93sD1qCz1POHMeta } from "/usr/src/app/pages/blog/[codeId].vue?macro=true";
import { default as indexgewR7USAlvMeta } from "/usr/src/app/pages/blog/index.vue?macro=true";
import { default as emailIf5gHBoGy5Meta } from "/usr/src/app/pages/confirm/email.vue?macro=true";
import { default as indexknsigkjikqMeta } from "/usr/src/app/pages/exchange/index.vue?macro=true";
import { default as _91requestId_93UNZxHqeAs2Meta } from "/usr/src/app/pages/exchange/requests/[requestId].vue?macro=true";
import { default as indexoxKq71rjKVMeta } from "/usr/src/app/pages/exchange/requests/index.vue?macro=true";
import { default as exchange2wRIknEGm8Meta } from "/usr/src/app/pages/exchange.vue?macro=true";
import { default as faqDkOohHQxeIMeta } from "/usr/src/app/pages/faq.vue?macro=true";
import { default as helpQYzekt5ZcHMeta } from "/usr/src/app/pages/help.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as newsT2RoX8WRasMeta } from "/usr/src/app/pages/news.vue?macro=true";
import { default as privicy_45policy1IBdBc5Nq2Meta } from "/usr/src/app/pages/privicy-policy.vue?macro=true";
import { default as _91userId_93s61DvyQgvIMeta } from "/usr/src/app/pages/profile/[userId].vue?macro=true";
import { default as ratesOxfGWLW8w0Meta } from "/usr/src/app/pages/rates.vue?macro=true";
import { default as registrationHsGWJw4mRLMeta } from "/usr/src/app/pages/registration.vue?macro=true";
import { default as reset_45passwordZej1Ub4tM0Meta } from "/usr/src/app/pages/reset-password.vue?macro=true";
import { default as support_45chatuHhkhatM9MMeta } from "/usr/src/app/pages/support-chat.vue?macro=true";
import { default as terms_45of_45usekooS9dlcBMMeta } from "/usr/src/app/pages/terms-of-use.vue?macro=true";
import { default as _91action_93uME32lIpIWMeta } from "/usr/src/app/pages/trade/[action].vue?macro=true";
import { default as chathvU3vFyJxPMeta } from "/usr/src/app/pages/trade/chat.vue?macro=true";
import { default as indexsCEeAFfwuvMeta } from "/usr/src/app/pages/trade/index.vue?macro=true";
import { default as my_45offersh2j4Q6KatBMeta } from "/usr/src/app/pages/trade/my-offers.vue?macro=true";
import { default as _91orderId_93BAO5frnMflMeta } from "/usr/src/app/pages/trade/orders/[orderId].vue?macro=true";
import { default as indexmJAwutfoeWMeta } from "/usr/src/app/pages/trade/orders/index.vue?macro=true";
import { default as ordersUiYkFP5OU7Meta } from "/usr/src/app/pages/trade/orders.vue?macro=true";
import { default as profileTENEwI8GN2Meta } from "/usr/src/app/pages/trade/profile.vue?macro=true";
import { default as trade58egNtb0yCMeta } from "/usr/src/app/pages/trade.vue?macro=true";
import { default as index9wmPuxyvMJMeta } from "/usr/src/app/pages/user/index.vue?macro=true";
import { default as notificationsG4TyXarPOYMeta } from "/usr/src/app/pages/user/notifications.vue?macro=true";
import { default as settingsLKQwpF56qGMeta } from "/usr/src/app/pages/user/settings.vue?macro=true";
import { default as walletLFoxqnXK8jMeta } from "/usr/src/app/pages/user/wallet.vue?macro=true";
import { default as userYgr9W7cUghMeta } from "/usr/src/app/pages/user.vue?macro=true";
export default [
  {
    name: "about___en",
    path: "/about",
    meta: aboutKJAmqyq7bDMeta || {},
    component: () => import("/usr/src/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___de",
    path: "/de/about",
    meta: aboutKJAmqyq7bDMeta || {},
    component: () => import("/usr/src/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___fr",
    path: "/fr/about",
    meta: aboutKJAmqyq7bDMeta || {},
    component: () => import("/usr/src/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___ru",
    path: "/ru/about",
    meta: aboutKJAmqyq7bDMeta || {},
    component: () => import("/usr/src/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___es",
    path: "/es/about",
    meta: aboutKJAmqyq7bDMeta || {},
    component: () => import("/usr/src/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___cn",
    path: "/cn/about",
    meta: aboutKJAmqyq7bDMeta || {},
    component: () => import("/usr/src/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "about___am",
    path: "/am/about",
    meta: aboutKJAmqyq7bDMeta || {},
    component: () => import("/usr/src/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: administrationDGZ6pUCGuuMeta?.name,
    path: "/administration",
    meta: administrationDGZ6pUCGuuMeta || {},
    component: () => import("/usr/src/app/pages/administration.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-chats-sessionId___en",
    path: "chats/:sessionId()",
    component: () => import("/usr/src/app/pages/administration/chats/[sessionId].vue").then(m => m.default || m)
  },
  {
    name: "administration-chats___en",
    path: "chats",
    component: () => import("/usr/src/app/pages/administration/chats/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-disputes-orderId___en",
    path: "disputes/:orderId()",
    component: () => import("/usr/src/app/pages/administration/disputes/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "administration-disputes___en",
    path: "disputes",
    component: () => import("/usr/src/app/pages/administration/disputes/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange___en",
    path: "exchange",
    component: () => import("/usr/src/app/pages/administration/exchange.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-exchange-requests-requestId___en",
    path: "requests/:requestId()",
    component: () => import("/usr/src/app/pages/administration/exchange/requests/[requestId].vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange-requests___en",
    path: "requests",
    component: () => import("/usr/src/app/pages/administration/exchange/requests/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange-setup___en",
    path: "setup",
    component: () => import("/usr/src/app/pages/administration/exchange/setup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "administration___en",
    path: "",
    meta: indexnMNUgWRJc2Meta || {},
    component: () => import("/usr/src/app/pages/administration/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-stats___en",
    path: "stats",
    component: () => import("/usr/src/app/pages/administration/stats.vue").then(m => m.default || m)
  },
  {
    name: "administration-users___en",
    path: "users",
    component: () => import("/usr/src/app/pages/administration/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: administrationDGZ6pUCGuuMeta?.name,
    path: "/de/administration",
    meta: administrationDGZ6pUCGuuMeta || {},
    component: () => import("/usr/src/app/pages/administration.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-chats-sessionId___de",
    path: "chats/:sessionId()",
    component: () => import("/usr/src/app/pages/administration/chats/[sessionId].vue").then(m => m.default || m)
  },
  {
    name: "administration-chats___de",
    path: "chats",
    component: () => import("/usr/src/app/pages/administration/chats/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-disputes-orderId___de",
    path: "disputes/:orderId()",
    component: () => import("/usr/src/app/pages/administration/disputes/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "administration-disputes___de",
    path: "disputes",
    component: () => import("/usr/src/app/pages/administration/disputes/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange___de",
    path: "exchange",
    component: () => import("/usr/src/app/pages/administration/exchange.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-exchange-requests-requestId___de",
    path: "requests/:requestId()",
    component: () => import("/usr/src/app/pages/administration/exchange/requests/[requestId].vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange-requests___de",
    path: "requests",
    component: () => import("/usr/src/app/pages/administration/exchange/requests/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange-setup___de",
    path: "setup",
    component: () => import("/usr/src/app/pages/administration/exchange/setup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "administration___de",
    path: "",
    meta: indexnMNUgWRJc2Meta || {},
    component: () => import("/usr/src/app/pages/administration/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-stats___de",
    path: "stats",
    component: () => import("/usr/src/app/pages/administration/stats.vue").then(m => m.default || m)
  },
  {
    name: "administration-users___de",
    path: "users",
    component: () => import("/usr/src/app/pages/administration/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: administrationDGZ6pUCGuuMeta?.name,
    path: "/fr/administration",
    meta: administrationDGZ6pUCGuuMeta || {},
    component: () => import("/usr/src/app/pages/administration.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-chats-sessionId___fr",
    path: "chats/:sessionId()",
    component: () => import("/usr/src/app/pages/administration/chats/[sessionId].vue").then(m => m.default || m)
  },
  {
    name: "administration-chats___fr",
    path: "chats",
    component: () => import("/usr/src/app/pages/administration/chats/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-disputes-orderId___fr",
    path: "disputes/:orderId()",
    component: () => import("/usr/src/app/pages/administration/disputes/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "administration-disputes___fr",
    path: "disputes",
    component: () => import("/usr/src/app/pages/administration/disputes/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange___fr",
    path: "exchange",
    component: () => import("/usr/src/app/pages/administration/exchange.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-exchange-requests-requestId___fr",
    path: "requests/:requestId()",
    component: () => import("/usr/src/app/pages/administration/exchange/requests/[requestId].vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange-requests___fr",
    path: "requests",
    component: () => import("/usr/src/app/pages/administration/exchange/requests/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange-setup___fr",
    path: "setup",
    component: () => import("/usr/src/app/pages/administration/exchange/setup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "administration___fr",
    path: "",
    meta: indexnMNUgWRJc2Meta || {},
    component: () => import("/usr/src/app/pages/administration/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-stats___fr",
    path: "stats",
    component: () => import("/usr/src/app/pages/administration/stats.vue").then(m => m.default || m)
  },
  {
    name: "administration-users___fr",
    path: "users",
    component: () => import("/usr/src/app/pages/administration/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: administrationDGZ6pUCGuuMeta?.name,
    path: "/ru/administration",
    meta: administrationDGZ6pUCGuuMeta || {},
    component: () => import("/usr/src/app/pages/administration.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-chats-sessionId___ru",
    path: "chats/:sessionId()",
    component: () => import("/usr/src/app/pages/administration/chats/[sessionId].vue").then(m => m.default || m)
  },
  {
    name: "administration-chats___ru",
    path: "chats",
    component: () => import("/usr/src/app/pages/administration/chats/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-disputes-orderId___ru",
    path: "disputes/:orderId()",
    component: () => import("/usr/src/app/pages/administration/disputes/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "administration-disputes___ru",
    path: "disputes",
    component: () => import("/usr/src/app/pages/administration/disputes/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange___ru",
    path: "exchange",
    component: () => import("/usr/src/app/pages/administration/exchange.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-exchange-requests-requestId___ru",
    path: "requests/:requestId()",
    component: () => import("/usr/src/app/pages/administration/exchange/requests/[requestId].vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange-requests___ru",
    path: "requests",
    component: () => import("/usr/src/app/pages/administration/exchange/requests/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange-setup___ru",
    path: "setup",
    component: () => import("/usr/src/app/pages/administration/exchange/setup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "administration___ru",
    path: "",
    meta: indexnMNUgWRJc2Meta || {},
    component: () => import("/usr/src/app/pages/administration/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-stats___ru",
    path: "stats",
    component: () => import("/usr/src/app/pages/administration/stats.vue").then(m => m.default || m)
  },
  {
    name: "administration-users___ru",
    path: "users",
    component: () => import("/usr/src/app/pages/administration/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: administrationDGZ6pUCGuuMeta?.name,
    path: "/es/administration",
    meta: administrationDGZ6pUCGuuMeta || {},
    component: () => import("/usr/src/app/pages/administration.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-chats-sessionId___es",
    path: "chats/:sessionId()",
    component: () => import("/usr/src/app/pages/administration/chats/[sessionId].vue").then(m => m.default || m)
  },
  {
    name: "administration-chats___es",
    path: "chats",
    component: () => import("/usr/src/app/pages/administration/chats/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-disputes-orderId___es",
    path: "disputes/:orderId()",
    component: () => import("/usr/src/app/pages/administration/disputes/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "administration-disputes___es",
    path: "disputes",
    component: () => import("/usr/src/app/pages/administration/disputes/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange___es",
    path: "exchange",
    component: () => import("/usr/src/app/pages/administration/exchange.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-exchange-requests-requestId___es",
    path: "requests/:requestId()",
    component: () => import("/usr/src/app/pages/administration/exchange/requests/[requestId].vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange-requests___es",
    path: "requests",
    component: () => import("/usr/src/app/pages/administration/exchange/requests/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange-setup___es",
    path: "setup",
    component: () => import("/usr/src/app/pages/administration/exchange/setup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "administration___es",
    path: "",
    meta: indexnMNUgWRJc2Meta || {},
    component: () => import("/usr/src/app/pages/administration/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-stats___es",
    path: "stats",
    component: () => import("/usr/src/app/pages/administration/stats.vue").then(m => m.default || m)
  },
  {
    name: "administration-users___es",
    path: "users",
    component: () => import("/usr/src/app/pages/administration/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: administrationDGZ6pUCGuuMeta?.name,
    path: "/cn/administration",
    meta: administrationDGZ6pUCGuuMeta || {},
    component: () => import("/usr/src/app/pages/administration.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-chats-sessionId___cn",
    path: "chats/:sessionId()",
    component: () => import("/usr/src/app/pages/administration/chats/[sessionId].vue").then(m => m.default || m)
  },
  {
    name: "administration-chats___cn",
    path: "chats",
    component: () => import("/usr/src/app/pages/administration/chats/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-disputes-orderId___cn",
    path: "disputes/:orderId()",
    component: () => import("/usr/src/app/pages/administration/disputes/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "administration-disputes___cn",
    path: "disputes",
    component: () => import("/usr/src/app/pages/administration/disputes/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange___cn",
    path: "exchange",
    component: () => import("/usr/src/app/pages/administration/exchange.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-exchange-requests-requestId___cn",
    path: "requests/:requestId()",
    component: () => import("/usr/src/app/pages/administration/exchange/requests/[requestId].vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange-requests___cn",
    path: "requests",
    component: () => import("/usr/src/app/pages/administration/exchange/requests/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange-setup___cn",
    path: "setup",
    component: () => import("/usr/src/app/pages/administration/exchange/setup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "administration___cn",
    path: "",
    meta: indexnMNUgWRJc2Meta || {},
    component: () => import("/usr/src/app/pages/administration/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-stats___cn",
    path: "stats",
    component: () => import("/usr/src/app/pages/administration/stats.vue").then(m => m.default || m)
  },
  {
    name: "administration-users___cn",
    path: "users",
    component: () => import("/usr/src/app/pages/administration/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: administrationDGZ6pUCGuuMeta?.name,
    path: "/am/administration",
    meta: administrationDGZ6pUCGuuMeta || {},
    component: () => import("/usr/src/app/pages/administration.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-chats-sessionId___am",
    path: "chats/:sessionId()",
    component: () => import("/usr/src/app/pages/administration/chats/[sessionId].vue").then(m => m.default || m)
  },
  {
    name: "administration-chats___am",
    path: "chats",
    component: () => import("/usr/src/app/pages/administration/chats/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-disputes-orderId___am",
    path: "disputes/:orderId()",
    component: () => import("/usr/src/app/pages/administration/disputes/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "administration-disputes___am",
    path: "disputes",
    component: () => import("/usr/src/app/pages/administration/disputes/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange___am",
    path: "exchange",
    component: () => import("/usr/src/app/pages/administration/exchange.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-exchange-requests-requestId___am",
    path: "requests/:requestId()",
    component: () => import("/usr/src/app/pages/administration/exchange/requests/[requestId].vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange-requests___am",
    path: "requests",
    component: () => import("/usr/src/app/pages/administration/exchange/requests/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-exchange-setup___am",
    path: "setup",
    component: () => import("/usr/src/app/pages/administration/exchange/setup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "administration___am",
    path: "",
    meta: indexnMNUgWRJc2Meta || {},
    component: () => import("/usr/src/app/pages/administration/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-stats___am",
    path: "stats",
    component: () => import("/usr/src/app/pages/administration/stats.vue").then(m => m.default || m)
  },
  {
    name: "administration-users___am",
    path: "users",
    component: () => import("/usr/src/app/pages/administration/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "alm-ctf-kyc___en",
    path: "/alm-ctf-kyc",
    component: () => import("/usr/src/app/pages/alm-ctf-kyc.vue").then(m => m.default || m)
  },
  {
    name: "alm-ctf-kyc___de",
    path: "/de/alm-ctf-kyc",
    component: () => import("/usr/src/app/pages/alm-ctf-kyc.vue").then(m => m.default || m)
  },
  {
    name: "alm-ctf-kyc___fr",
    path: "/fr/alm-ctf-kyc",
    component: () => import("/usr/src/app/pages/alm-ctf-kyc.vue").then(m => m.default || m)
  },
  {
    name: "alm-ctf-kyc___ru",
    path: "/ru/alm-ctf-kyc",
    component: () => import("/usr/src/app/pages/alm-ctf-kyc.vue").then(m => m.default || m)
  },
  {
    name: "alm-ctf-kyc___es",
    path: "/es/alm-ctf-kyc",
    component: () => import("/usr/src/app/pages/alm-ctf-kyc.vue").then(m => m.default || m)
  },
  {
    name: "alm-ctf-kyc___cn",
    path: "/cn/alm-ctf-kyc",
    component: () => import("/usr/src/app/pages/alm-ctf-kyc.vue").then(m => m.default || m)
  },
  {
    name: "alm-ctf-kyc___am",
    path: "/am/alm-ctf-kyc",
    component: () => import("/usr/src/app/pages/alm-ctf-kyc.vue").then(m => m.default || m)
  },
  {
    name: "beginners-guide___en",
    path: "/beginners-guide",
    meta: beginners_45guideXY0ITcISlEMeta || {},
    component: () => import("/usr/src/app/pages/beginners-guide.vue").then(m => m.default || m)
  },
  {
    name: "beginners-guide___de",
    path: "/de/beginners-guide",
    meta: beginners_45guideXY0ITcISlEMeta || {},
    component: () => import("/usr/src/app/pages/beginners-guide.vue").then(m => m.default || m)
  },
  {
    name: "beginners-guide___fr",
    path: "/fr/beginners-guide",
    meta: beginners_45guideXY0ITcISlEMeta || {},
    component: () => import("/usr/src/app/pages/beginners-guide.vue").then(m => m.default || m)
  },
  {
    name: "beginners-guide___ru",
    path: "/ru/beginners-guide",
    meta: beginners_45guideXY0ITcISlEMeta || {},
    component: () => import("/usr/src/app/pages/beginners-guide.vue").then(m => m.default || m)
  },
  {
    name: "beginners-guide___es",
    path: "/es/beginners-guide",
    meta: beginners_45guideXY0ITcISlEMeta || {},
    component: () => import("/usr/src/app/pages/beginners-guide.vue").then(m => m.default || m)
  },
  {
    name: "beginners-guide___cn",
    path: "/cn/beginners-guide",
    meta: beginners_45guideXY0ITcISlEMeta || {},
    component: () => import("/usr/src/app/pages/beginners-guide.vue").then(m => m.default || m)
  },
  {
    name: "beginners-guide___am",
    path: "/am/beginners-guide",
    meta: beginners_45guideXY0ITcISlEMeta || {},
    component: () => import("/usr/src/app/pages/beginners-guide.vue").then(m => m.default || m)
  },
  {
    name: "blog-codeId___en",
    path: "/blog/:codeId()",
    meta: _91codeId_93sD1qCz1POHMeta || {},
    component: () => import("/usr/src/app/pages/blog/[codeId].vue").then(m => m.default || m)
  },
  {
    name: "blog-codeId___de",
    path: "/de/blog/:codeId()",
    meta: _91codeId_93sD1qCz1POHMeta || {},
    component: () => import("/usr/src/app/pages/blog/[codeId].vue").then(m => m.default || m)
  },
  {
    name: "blog-codeId___fr",
    path: "/fr/blog/:codeId()",
    meta: _91codeId_93sD1qCz1POHMeta || {},
    component: () => import("/usr/src/app/pages/blog/[codeId].vue").then(m => m.default || m)
  },
  {
    name: "blog-codeId___ru",
    path: "/ru/blog/:codeId()",
    meta: _91codeId_93sD1qCz1POHMeta || {},
    component: () => import("/usr/src/app/pages/blog/[codeId].vue").then(m => m.default || m)
  },
  {
    name: "blog-codeId___es",
    path: "/es/blog/:codeId()",
    meta: _91codeId_93sD1qCz1POHMeta || {},
    component: () => import("/usr/src/app/pages/blog/[codeId].vue").then(m => m.default || m)
  },
  {
    name: "blog-codeId___cn",
    path: "/cn/blog/:codeId()",
    meta: _91codeId_93sD1qCz1POHMeta || {},
    component: () => import("/usr/src/app/pages/blog/[codeId].vue").then(m => m.default || m)
  },
  {
    name: "blog-codeId___am",
    path: "/am/blog/:codeId()",
    meta: _91codeId_93sD1qCz1POHMeta || {},
    component: () => import("/usr/src/app/pages/blog/[codeId].vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/blog",
    meta: indexgewR7USAlvMeta || {},
    component: () => import("/usr/src/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___de",
    path: "/de/blog",
    meta: indexgewR7USAlvMeta || {},
    component: () => import("/usr/src/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___fr",
    path: "/fr/blog",
    meta: indexgewR7USAlvMeta || {},
    component: () => import("/usr/src/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___ru",
    path: "/ru/blog",
    meta: indexgewR7USAlvMeta || {},
    component: () => import("/usr/src/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___es",
    path: "/es/blog",
    meta: indexgewR7USAlvMeta || {},
    component: () => import("/usr/src/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___cn",
    path: "/cn/blog",
    meta: indexgewR7USAlvMeta || {},
    component: () => import("/usr/src/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___am",
    path: "/am/blog",
    meta: indexgewR7USAlvMeta || {},
    component: () => import("/usr/src/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "confirm-email___en",
    path: "/confirm/email",
    meta: emailIf5gHBoGy5Meta || {},
    component: () => import("/usr/src/app/pages/confirm/email.vue").then(m => m.default || m)
  },
  {
    name: "confirm-email___de",
    path: "/de/confirm/email",
    meta: emailIf5gHBoGy5Meta || {},
    component: () => import("/usr/src/app/pages/confirm/email.vue").then(m => m.default || m)
  },
  {
    name: "confirm-email___fr",
    path: "/fr/confirm/email",
    meta: emailIf5gHBoGy5Meta || {},
    component: () => import("/usr/src/app/pages/confirm/email.vue").then(m => m.default || m)
  },
  {
    name: "confirm-email___ru",
    path: "/ru/confirm/email",
    meta: emailIf5gHBoGy5Meta || {},
    component: () => import("/usr/src/app/pages/confirm/email.vue").then(m => m.default || m)
  },
  {
    name: "confirm-email___es",
    path: "/es/confirm/email",
    meta: emailIf5gHBoGy5Meta || {},
    component: () => import("/usr/src/app/pages/confirm/email.vue").then(m => m.default || m)
  },
  {
    name: "confirm-email___cn",
    path: "/cn/confirm/email",
    meta: emailIf5gHBoGy5Meta || {},
    component: () => import("/usr/src/app/pages/confirm/email.vue").then(m => m.default || m)
  },
  {
    name: "confirm-email___am",
    path: "/am/confirm/email",
    meta: emailIf5gHBoGy5Meta || {},
    component: () => import("/usr/src/app/pages/confirm/email.vue").then(m => m.default || m)
  },
  {
    name: exchange2wRIknEGm8Meta?.name,
    path: "/exchange",
    component: () => import("/usr/src/app/pages/exchange.vue").then(m => m.default || m),
    children: [
  {
    name: "exchange___en",
    path: "",
    component: () => import("/usr/src/app/pages/exchange/index.vue").then(m => m.default || m)
  },
  {
    name: "exchange-requests-requestId___en",
    path: "requests/:requestId()",
    component: () => import("/usr/src/app/pages/exchange/requests/[requestId].vue").then(m => m.default || m)
  },
  {
    name: "exchange-requests___en",
    path: "requests",
    component: () => import("/usr/src/app/pages/exchange/requests/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: exchange2wRIknEGm8Meta?.name,
    path: "/de/exchange",
    component: () => import("/usr/src/app/pages/exchange.vue").then(m => m.default || m),
    children: [
  {
    name: "exchange___de",
    path: "",
    component: () => import("/usr/src/app/pages/exchange/index.vue").then(m => m.default || m)
  },
  {
    name: "exchange-requests-requestId___de",
    path: "requests/:requestId()",
    component: () => import("/usr/src/app/pages/exchange/requests/[requestId].vue").then(m => m.default || m)
  },
  {
    name: "exchange-requests___de",
    path: "requests",
    component: () => import("/usr/src/app/pages/exchange/requests/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: exchange2wRIknEGm8Meta?.name,
    path: "/fr/exchange",
    component: () => import("/usr/src/app/pages/exchange.vue").then(m => m.default || m),
    children: [
  {
    name: "exchange___fr",
    path: "",
    component: () => import("/usr/src/app/pages/exchange/index.vue").then(m => m.default || m)
  },
  {
    name: "exchange-requests-requestId___fr",
    path: "requests/:requestId()",
    component: () => import("/usr/src/app/pages/exchange/requests/[requestId].vue").then(m => m.default || m)
  },
  {
    name: "exchange-requests___fr",
    path: "requests",
    component: () => import("/usr/src/app/pages/exchange/requests/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: exchange2wRIknEGm8Meta?.name,
    path: "/ru/exchange",
    component: () => import("/usr/src/app/pages/exchange.vue").then(m => m.default || m),
    children: [
  {
    name: "exchange___ru",
    path: "",
    component: () => import("/usr/src/app/pages/exchange/index.vue").then(m => m.default || m)
  },
  {
    name: "exchange-requests-requestId___ru",
    path: "requests/:requestId()",
    component: () => import("/usr/src/app/pages/exchange/requests/[requestId].vue").then(m => m.default || m)
  },
  {
    name: "exchange-requests___ru",
    path: "requests",
    component: () => import("/usr/src/app/pages/exchange/requests/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: exchange2wRIknEGm8Meta?.name,
    path: "/es/exchange",
    component: () => import("/usr/src/app/pages/exchange.vue").then(m => m.default || m),
    children: [
  {
    name: "exchange___es",
    path: "",
    component: () => import("/usr/src/app/pages/exchange/index.vue").then(m => m.default || m)
  },
  {
    name: "exchange-requests-requestId___es",
    path: "requests/:requestId()",
    component: () => import("/usr/src/app/pages/exchange/requests/[requestId].vue").then(m => m.default || m)
  },
  {
    name: "exchange-requests___es",
    path: "requests",
    component: () => import("/usr/src/app/pages/exchange/requests/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: exchange2wRIknEGm8Meta?.name,
    path: "/cn/exchange",
    component: () => import("/usr/src/app/pages/exchange.vue").then(m => m.default || m),
    children: [
  {
    name: "exchange___cn",
    path: "",
    component: () => import("/usr/src/app/pages/exchange/index.vue").then(m => m.default || m)
  },
  {
    name: "exchange-requests-requestId___cn",
    path: "requests/:requestId()",
    component: () => import("/usr/src/app/pages/exchange/requests/[requestId].vue").then(m => m.default || m)
  },
  {
    name: "exchange-requests___cn",
    path: "requests",
    component: () => import("/usr/src/app/pages/exchange/requests/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: exchange2wRIknEGm8Meta?.name,
    path: "/am/exchange",
    component: () => import("/usr/src/app/pages/exchange.vue").then(m => m.default || m),
    children: [
  {
    name: "exchange___am",
    path: "",
    component: () => import("/usr/src/app/pages/exchange/index.vue").then(m => m.default || m)
  },
  {
    name: "exchange-requests-requestId___am",
    path: "requests/:requestId()",
    component: () => import("/usr/src/app/pages/exchange/requests/[requestId].vue").then(m => m.default || m)
  },
  {
    name: "exchange-requests___am",
    path: "requests",
    component: () => import("/usr/src/app/pages/exchange/requests/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "faq___en",
    path: "/faq",
    meta: faqDkOohHQxeIMeta || {},
    component: () => import("/usr/src/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___de",
    path: "/de/faq",
    meta: faqDkOohHQxeIMeta || {},
    component: () => import("/usr/src/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___fr",
    path: "/fr/faq",
    meta: faqDkOohHQxeIMeta || {},
    component: () => import("/usr/src/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___ru",
    path: "/ru/faq",
    meta: faqDkOohHQxeIMeta || {},
    component: () => import("/usr/src/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___es",
    path: "/es/faq",
    meta: faqDkOohHQxeIMeta || {},
    component: () => import("/usr/src/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___cn",
    path: "/cn/faq",
    meta: faqDkOohHQxeIMeta || {},
    component: () => import("/usr/src/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___am",
    path: "/am/faq",
    meta: faqDkOohHQxeIMeta || {},
    component: () => import("/usr/src/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "help___en",
    path: "/help",
    meta: helpQYzekt5ZcHMeta || {},
    component: () => import("/usr/src/app/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "help___de",
    path: "/de/help",
    meta: helpQYzekt5ZcHMeta || {},
    component: () => import("/usr/src/app/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "help___fr",
    path: "/fr/help",
    meta: helpQYzekt5ZcHMeta || {},
    component: () => import("/usr/src/app/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "help___ru",
    path: "/ru/help",
    meta: helpQYzekt5ZcHMeta || {},
    component: () => import("/usr/src/app/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "help___es",
    path: "/es/help",
    meta: helpQYzekt5ZcHMeta || {},
    component: () => import("/usr/src/app/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "help___cn",
    path: "/cn/help",
    meta: helpQYzekt5ZcHMeta || {},
    component: () => import("/usr/src/app/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "help___am",
    path: "/am/help",
    meta: helpQYzekt5ZcHMeta || {},
    component: () => import("/usr/src/app/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ru",
    path: "/ru",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___cn",
    path: "/cn",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___am",
    path: "/am",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___de",
    path: "/de/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___fr",
    path: "/fr/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___ru",
    path: "/ru/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___es",
    path: "/es/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___cn",
    path: "/cn/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___am",
    path: "/am/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "news___en",
    path: "/news",
    meta: newsT2RoX8WRasMeta || {},
    component: () => import("/usr/src/app/pages/news.vue").then(m => m.default || m)
  },
  {
    name: "news___de",
    path: "/de/news",
    meta: newsT2RoX8WRasMeta || {},
    component: () => import("/usr/src/app/pages/news.vue").then(m => m.default || m)
  },
  {
    name: "news___fr",
    path: "/fr/news",
    meta: newsT2RoX8WRasMeta || {},
    component: () => import("/usr/src/app/pages/news.vue").then(m => m.default || m)
  },
  {
    name: "news___ru",
    path: "/ru/news",
    meta: newsT2RoX8WRasMeta || {},
    component: () => import("/usr/src/app/pages/news.vue").then(m => m.default || m)
  },
  {
    name: "news___es",
    path: "/es/news",
    meta: newsT2RoX8WRasMeta || {},
    component: () => import("/usr/src/app/pages/news.vue").then(m => m.default || m)
  },
  {
    name: "news___cn",
    path: "/cn/news",
    meta: newsT2RoX8WRasMeta || {},
    component: () => import("/usr/src/app/pages/news.vue").then(m => m.default || m)
  },
  {
    name: "news___am",
    path: "/am/news",
    meta: newsT2RoX8WRasMeta || {},
    component: () => import("/usr/src/app/pages/news.vue").then(m => m.default || m)
  },
  {
    name: "privicy-policy___en",
    path: "/privicy-policy",
    meta: privicy_45policy1IBdBc5Nq2Meta || {},
    component: () => import("/usr/src/app/pages/privicy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privicy-policy___de",
    path: "/de/privicy-policy",
    meta: privicy_45policy1IBdBc5Nq2Meta || {},
    component: () => import("/usr/src/app/pages/privicy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privicy-policy___fr",
    path: "/fr/privicy-policy",
    meta: privicy_45policy1IBdBc5Nq2Meta || {},
    component: () => import("/usr/src/app/pages/privicy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privicy-policy___ru",
    path: "/ru/privicy-policy",
    meta: privicy_45policy1IBdBc5Nq2Meta || {},
    component: () => import("/usr/src/app/pages/privicy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privicy-policy___es",
    path: "/es/privicy-policy",
    meta: privicy_45policy1IBdBc5Nq2Meta || {},
    component: () => import("/usr/src/app/pages/privicy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privicy-policy___cn",
    path: "/cn/privicy-policy",
    meta: privicy_45policy1IBdBc5Nq2Meta || {},
    component: () => import("/usr/src/app/pages/privicy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privicy-policy___am",
    path: "/am/privicy-policy",
    meta: privicy_45policy1IBdBc5Nq2Meta || {},
    component: () => import("/usr/src/app/pages/privicy-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-userId___en",
    path: "/profile/:userId()",
    meta: _91userId_93s61DvyQgvIMeta || {},
    component: () => import("/usr/src/app/pages/profile/[userId].vue").then(m => m.default || m)
  },
  {
    name: "profile-userId___de",
    path: "/de/profile/:userId()",
    meta: _91userId_93s61DvyQgvIMeta || {},
    component: () => import("/usr/src/app/pages/profile/[userId].vue").then(m => m.default || m)
  },
  {
    name: "profile-userId___fr",
    path: "/fr/profile/:userId()",
    meta: _91userId_93s61DvyQgvIMeta || {},
    component: () => import("/usr/src/app/pages/profile/[userId].vue").then(m => m.default || m)
  },
  {
    name: "profile-userId___ru",
    path: "/ru/profile/:userId()",
    meta: _91userId_93s61DvyQgvIMeta || {},
    component: () => import("/usr/src/app/pages/profile/[userId].vue").then(m => m.default || m)
  },
  {
    name: "profile-userId___es",
    path: "/es/profile/:userId()",
    meta: _91userId_93s61DvyQgvIMeta || {},
    component: () => import("/usr/src/app/pages/profile/[userId].vue").then(m => m.default || m)
  },
  {
    name: "profile-userId___cn",
    path: "/cn/profile/:userId()",
    meta: _91userId_93s61DvyQgvIMeta || {},
    component: () => import("/usr/src/app/pages/profile/[userId].vue").then(m => m.default || m)
  },
  {
    name: "profile-userId___am",
    path: "/am/profile/:userId()",
    meta: _91userId_93s61DvyQgvIMeta || {},
    component: () => import("/usr/src/app/pages/profile/[userId].vue").then(m => m.default || m)
  },
  {
    name: "rates___en",
    path: "/rates",
    meta: ratesOxfGWLW8w0Meta || {},
    component: () => import("/usr/src/app/pages/rates.vue").then(m => m.default || m)
  },
  {
    name: "rates___de",
    path: "/de/rates",
    meta: ratesOxfGWLW8w0Meta || {},
    component: () => import("/usr/src/app/pages/rates.vue").then(m => m.default || m)
  },
  {
    name: "rates___fr",
    path: "/fr/rates",
    meta: ratesOxfGWLW8w0Meta || {},
    component: () => import("/usr/src/app/pages/rates.vue").then(m => m.default || m)
  },
  {
    name: "rates___ru",
    path: "/ru/rates",
    meta: ratesOxfGWLW8w0Meta || {},
    component: () => import("/usr/src/app/pages/rates.vue").then(m => m.default || m)
  },
  {
    name: "rates___es",
    path: "/es/rates",
    meta: ratesOxfGWLW8w0Meta || {},
    component: () => import("/usr/src/app/pages/rates.vue").then(m => m.default || m)
  },
  {
    name: "rates___cn",
    path: "/cn/rates",
    meta: ratesOxfGWLW8w0Meta || {},
    component: () => import("/usr/src/app/pages/rates.vue").then(m => m.default || m)
  },
  {
    name: "rates___am",
    path: "/am/rates",
    meta: ratesOxfGWLW8w0Meta || {},
    component: () => import("/usr/src/app/pages/rates.vue").then(m => m.default || m)
  },
  {
    name: "registration___en",
    path: "/registration",
    meta: registrationHsGWJw4mRLMeta || {},
    component: () => import("/usr/src/app/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "registration___de",
    path: "/de/registration",
    meta: registrationHsGWJw4mRLMeta || {},
    component: () => import("/usr/src/app/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "registration___fr",
    path: "/fr/registration",
    meta: registrationHsGWJw4mRLMeta || {},
    component: () => import("/usr/src/app/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "registration___ru",
    path: "/ru/registration",
    meta: registrationHsGWJw4mRLMeta || {},
    component: () => import("/usr/src/app/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "registration___es",
    path: "/es/registration",
    meta: registrationHsGWJw4mRLMeta || {},
    component: () => import("/usr/src/app/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "registration___cn",
    path: "/cn/registration",
    meta: registrationHsGWJw4mRLMeta || {},
    component: () => import("/usr/src/app/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "registration___am",
    path: "/am/registration",
    meta: registrationHsGWJw4mRLMeta || {},
    component: () => import("/usr/src/app/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___en",
    path: "/reset-password",
    meta: reset_45passwordZej1Ub4tM0Meta || {},
    component: () => import("/usr/src/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___de",
    path: "/de/reset-password",
    meta: reset_45passwordZej1Ub4tM0Meta || {},
    component: () => import("/usr/src/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___fr",
    path: "/fr/reset-password",
    meta: reset_45passwordZej1Ub4tM0Meta || {},
    component: () => import("/usr/src/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___ru",
    path: "/ru/reset-password",
    meta: reset_45passwordZej1Ub4tM0Meta || {},
    component: () => import("/usr/src/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___es",
    path: "/es/reset-password",
    meta: reset_45passwordZej1Ub4tM0Meta || {},
    component: () => import("/usr/src/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___cn",
    path: "/cn/reset-password",
    meta: reset_45passwordZej1Ub4tM0Meta || {},
    component: () => import("/usr/src/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___am",
    path: "/am/reset-password",
    meta: reset_45passwordZej1Ub4tM0Meta || {},
    component: () => import("/usr/src/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "support-chat___en",
    path: "/support-chat",
    component: () => import("/usr/src/app/pages/support-chat.vue").then(m => m.default || m)
  },
  {
    name: "support-chat___de",
    path: "/de/support-chat",
    component: () => import("/usr/src/app/pages/support-chat.vue").then(m => m.default || m)
  },
  {
    name: "support-chat___fr",
    path: "/fr/support-chat",
    component: () => import("/usr/src/app/pages/support-chat.vue").then(m => m.default || m)
  },
  {
    name: "support-chat___ru",
    path: "/ru/support-chat",
    component: () => import("/usr/src/app/pages/support-chat.vue").then(m => m.default || m)
  },
  {
    name: "support-chat___es",
    path: "/es/support-chat",
    component: () => import("/usr/src/app/pages/support-chat.vue").then(m => m.default || m)
  },
  {
    name: "support-chat___cn",
    path: "/cn/support-chat",
    component: () => import("/usr/src/app/pages/support-chat.vue").then(m => m.default || m)
  },
  {
    name: "support-chat___am",
    path: "/am/support-chat",
    component: () => import("/usr/src/app/pages/support-chat.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use___en",
    path: "/terms-of-use",
    meta: terms_45of_45usekooS9dlcBMMeta || {},
    component: () => import("/usr/src/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use___de",
    path: "/de/terms-of-use",
    meta: terms_45of_45usekooS9dlcBMMeta || {},
    component: () => import("/usr/src/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use___fr",
    path: "/fr/terms-of-use",
    meta: terms_45of_45usekooS9dlcBMMeta || {},
    component: () => import("/usr/src/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use___ru",
    path: "/ru/terms-of-use",
    meta: terms_45of_45usekooS9dlcBMMeta || {},
    component: () => import("/usr/src/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use___es",
    path: "/es/terms-of-use",
    meta: terms_45of_45usekooS9dlcBMMeta || {},
    component: () => import("/usr/src/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use___cn",
    path: "/cn/terms-of-use",
    meta: terms_45of_45usekooS9dlcBMMeta || {},
    component: () => import("/usr/src/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use___am",
    path: "/am/terms-of-use",
    meta: terms_45of_45usekooS9dlcBMMeta || {},
    component: () => import("/usr/src/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: trade58egNtb0yCMeta?.name,
    path: "/trade",
    component: () => import("/usr/src/app/pages/trade.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-action___en",
    path: ":action()",
    meta: _91action_93uME32lIpIWMeta || {},
    component: () => import("/usr/src/app/pages/trade/[action].vue").then(m => m.default || m)
  },
  {
    name: "trade-chat___en",
    path: "chat",
    meta: chathvU3vFyJxPMeta || {},
    component: () => import("/usr/src/app/pages/trade/chat.vue").then(m => m.default || m)
  },
  {
    name: "trade___en",
    path: "",
    component: () => import("/usr/src/app/pages/trade/index.vue").then(m => m.default || m)
  },
  {
    name: "trade-my-offers___en",
    path: "my-offers",
    meta: my_45offersh2j4Q6KatBMeta || {},
    component: () => import("/usr/src/app/pages/trade/my-offers.vue").then(m => m.default || m)
  },
  {
    name: ordersUiYkFP5OU7Meta?.name,
    path: "orders",
    meta: ordersUiYkFP5OU7Meta || {},
    component: () => import("/usr/src/app/pages/trade/orders.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-orders-orderId___en",
    path: ":orderId()",
    meta: _91orderId_93BAO5frnMflMeta || {},
    component: () => import("/usr/src/app/pages/trade/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "trade-orders___en",
    path: "",
    meta: indexmJAwutfoeWMeta || {},
    component: () => import("/usr/src/app/pages/trade/orders/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "trade-profile___en",
    path: "profile",
    meta: profileTENEwI8GN2Meta || {},
    component: () => import("/usr/src/app/pages/trade/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: trade58egNtb0yCMeta?.name,
    path: "/de/trade",
    component: () => import("/usr/src/app/pages/trade.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-action___de",
    path: ":action()",
    meta: _91action_93uME32lIpIWMeta || {},
    component: () => import("/usr/src/app/pages/trade/[action].vue").then(m => m.default || m)
  },
  {
    name: "trade-chat___de",
    path: "chat",
    meta: chathvU3vFyJxPMeta || {},
    component: () => import("/usr/src/app/pages/trade/chat.vue").then(m => m.default || m)
  },
  {
    name: "trade___de",
    path: "",
    component: () => import("/usr/src/app/pages/trade/index.vue").then(m => m.default || m)
  },
  {
    name: "trade-my-offers___de",
    path: "my-offers",
    meta: my_45offersh2j4Q6KatBMeta || {},
    component: () => import("/usr/src/app/pages/trade/my-offers.vue").then(m => m.default || m)
  },
  {
    name: ordersUiYkFP5OU7Meta?.name,
    path: "orders",
    meta: ordersUiYkFP5OU7Meta || {},
    component: () => import("/usr/src/app/pages/trade/orders.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-orders-orderId___de",
    path: ":orderId()",
    meta: _91orderId_93BAO5frnMflMeta || {},
    component: () => import("/usr/src/app/pages/trade/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "trade-orders___de",
    path: "",
    meta: indexmJAwutfoeWMeta || {},
    component: () => import("/usr/src/app/pages/trade/orders/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "trade-profile___de",
    path: "profile",
    meta: profileTENEwI8GN2Meta || {},
    component: () => import("/usr/src/app/pages/trade/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: trade58egNtb0yCMeta?.name,
    path: "/fr/trade",
    component: () => import("/usr/src/app/pages/trade.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-action___fr",
    path: ":action()",
    meta: _91action_93uME32lIpIWMeta || {},
    component: () => import("/usr/src/app/pages/trade/[action].vue").then(m => m.default || m)
  },
  {
    name: "trade-chat___fr",
    path: "chat",
    meta: chathvU3vFyJxPMeta || {},
    component: () => import("/usr/src/app/pages/trade/chat.vue").then(m => m.default || m)
  },
  {
    name: "trade___fr",
    path: "",
    component: () => import("/usr/src/app/pages/trade/index.vue").then(m => m.default || m)
  },
  {
    name: "trade-my-offers___fr",
    path: "my-offers",
    meta: my_45offersh2j4Q6KatBMeta || {},
    component: () => import("/usr/src/app/pages/trade/my-offers.vue").then(m => m.default || m)
  },
  {
    name: ordersUiYkFP5OU7Meta?.name,
    path: "orders",
    meta: ordersUiYkFP5OU7Meta || {},
    component: () => import("/usr/src/app/pages/trade/orders.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-orders-orderId___fr",
    path: ":orderId()",
    meta: _91orderId_93BAO5frnMflMeta || {},
    component: () => import("/usr/src/app/pages/trade/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "trade-orders___fr",
    path: "",
    meta: indexmJAwutfoeWMeta || {},
    component: () => import("/usr/src/app/pages/trade/orders/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "trade-profile___fr",
    path: "profile",
    meta: profileTENEwI8GN2Meta || {},
    component: () => import("/usr/src/app/pages/trade/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: trade58egNtb0yCMeta?.name,
    path: "/ru/trade",
    component: () => import("/usr/src/app/pages/trade.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-action___ru",
    path: ":action()",
    meta: _91action_93uME32lIpIWMeta || {},
    component: () => import("/usr/src/app/pages/trade/[action].vue").then(m => m.default || m)
  },
  {
    name: "trade-chat___ru",
    path: "chat",
    meta: chathvU3vFyJxPMeta || {},
    component: () => import("/usr/src/app/pages/trade/chat.vue").then(m => m.default || m)
  },
  {
    name: "trade___ru",
    path: "",
    component: () => import("/usr/src/app/pages/trade/index.vue").then(m => m.default || m)
  },
  {
    name: "trade-my-offers___ru",
    path: "my-offers",
    meta: my_45offersh2j4Q6KatBMeta || {},
    component: () => import("/usr/src/app/pages/trade/my-offers.vue").then(m => m.default || m)
  },
  {
    name: ordersUiYkFP5OU7Meta?.name,
    path: "orders",
    meta: ordersUiYkFP5OU7Meta || {},
    component: () => import("/usr/src/app/pages/trade/orders.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-orders-orderId___ru",
    path: ":orderId()",
    meta: _91orderId_93BAO5frnMflMeta || {},
    component: () => import("/usr/src/app/pages/trade/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "trade-orders___ru",
    path: "",
    meta: indexmJAwutfoeWMeta || {},
    component: () => import("/usr/src/app/pages/trade/orders/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "trade-profile___ru",
    path: "profile",
    meta: profileTENEwI8GN2Meta || {},
    component: () => import("/usr/src/app/pages/trade/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: trade58egNtb0yCMeta?.name,
    path: "/es/trade",
    component: () => import("/usr/src/app/pages/trade.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-action___es",
    path: ":action()",
    meta: _91action_93uME32lIpIWMeta || {},
    component: () => import("/usr/src/app/pages/trade/[action].vue").then(m => m.default || m)
  },
  {
    name: "trade-chat___es",
    path: "chat",
    meta: chathvU3vFyJxPMeta || {},
    component: () => import("/usr/src/app/pages/trade/chat.vue").then(m => m.default || m)
  },
  {
    name: "trade___es",
    path: "",
    component: () => import("/usr/src/app/pages/trade/index.vue").then(m => m.default || m)
  },
  {
    name: "trade-my-offers___es",
    path: "my-offers",
    meta: my_45offersh2j4Q6KatBMeta || {},
    component: () => import("/usr/src/app/pages/trade/my-offers.vue").then(m => m.default || m)
  },
  {
    name: ordersUiYkFP5OU7Meta?.name,
    path: "orders",
    meta: ordersUiYkFP5OU7Meta || {},
    component: () => import("/usr/src/app/pages/trade/orders.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-orders-orderId___es",
    path: ":orderId()",
    meta: _91orderId_93BAO5frnMflMeta || {},
    component: () => import("/usr/src/app/pages/trade/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "trade-orders___es",
    path: "",
    meta: indexmJAwutfoeWMeta || {},
    component: () => import("/usr/src/app/pages/trade/orders/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "trade-profile___es",
    path: "profile",
    meta: profileTENEwI8GN2Meta || {},
    component: () => import("/usr/src/app/pages/trade/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: trade58egNtb0yCMeta?.name,
    path: "/cn/trade",
    component: () => import("/usr/src/app/pages/trade.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-action___cn",
    path: ":action()",
    meta: _91action_93uME32lIpIWMeta || {},
    component: () => import("/usr/src/app/pages/trade/[action].vue").then(m => m.default || m)
  },
  {
    name: "trade-chat___cn",
    path: "chat",
    meta: chathvU3vFyJxPMeta || {},
    component: () => import("/usr/src/app/pages/trade/chat.vue").then(m => m.default || m)
  },
  {
    name: "trade___cn",
    path: "",
    component: () => import("/usr/src/app/pages/trade/index.vue").then(m => m.default || m)
  },
  {
    name: "trade-my-offers___cn",
    path: "my-offers",
    meta: my_45offersh2j4Q6KatBMeta || {},
    component: () => import("/usr/src/app/pages/trade/my-offers.vue").then(m => m.default || m)
  },
  {
    name: ordersUiYkFP5OU7Meta?.name,
    path: "orders",
    meta: ordersUiYkFP5OU7Meta || {},
    component: () => import("/usr/src/app/pages/trade/orders.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-orders-orderId___cn",
    path: ":orderId()",
    meta: _91orderId_93BAO5frnMflMeta || {},
    component: () => import("/usr/src/app/pages/trade/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "trade-orders___cn",
    path: "",
    meta: indexmJAwutfoeWMeta || {},
    component: () => import("/usr/src/app/pages/trade/orders/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "trade-profile___cn",
    path: "profile",
    meta: profileTENEwI8GN2Meta || {},
    component: () => import("/usr/src/app/pages/trade/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: trade58egNtb0yCMeta?.name,
    path: "/am/trade",
    component: () => import("/usr/src/app/pages/trade.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-action___am",
    path: ":action()",
    meta: _91action_93uME32lIpIWMeta || {},
    component: () => import("/usr/src/app/pages/trade/[action].vue").then(m => m.default || m)
  },
  {
    name: "trade-chat___am",
    path: "chat",
    meta: chathvU3vFyJxPMeta || {},
    component: () => import("/usr/src/app/pages/trade/chat.vue").then(m => m.default || m)
  },
  {
    name: "trade___am",
    path: "",
    component: () => import("/usr/src/app/pages/trade/index.vue").then(m => m.default || m)
  },
  {
    name: "trade-my-offers___am",
    path: "my-offers",
    meta: my_45offersh2j4Q6KatBMeta || {},
    component: () => import("/usr/src/app/pages/trade/my-offers.vue").then(m => m.default || m)
  },
  {
    name: ordersUiYkFP5OU7Meta?.name,
    path: "orders",
    meta: ordersUiYkFP5OU7Meta || {},
    component: () => import("/usr/src/app/pages/trade/orders.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-orders-orderId___am",
    path: ":orderId()",
    meta: _91orderId_93BAO5frnMflMeta || {},
    component: () => import("/usr/src/app/pages/trade/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "trade-orders___am",
    path: "",
    meta: indexmJAwutfoeWMeta || {},
    component: () => import("/usr/src/app/pages/trade/orders/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "trade-profile___am",
    path: "profile",
    meta: profileTENEwI8GN2Meta || {},
    component: () => import("/usr/src/app/pages/trade/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: userYgr9W7cUghMeta?.name,
    path: "/user",
    meta: userYgr9W7cUghMeta || {},
    component: () => import("/usr/src/app/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: "user___en",
    path: "",
    component: () => import("/usr/src/app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-notifications___en",
    path: "notifications",
    component: () => import("/usr/src/app/pages/user/notifications.vue").then(m => m.default || m)
  },
  {
    name: "user-settings___en",
    path: "settings",
    component: () => import("/usr/src/app/pages/user/settings.vue").then(m => m.default || m)
  },
  {
    name: "user-wallet___en",
    path: "wallet",
    component: () => import("/usr/src/app/pages/user/wallet.vue").then(m => m.default || m)
  }
]
  },
  {
    name: userYgr9W7cUghMeta?.name,
    path: "/de/user",
    meta: userYgr9W7cUghMeta || {},
    component: () => import("/usr/src/app/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: "user___de",
    path: "",
    component: () => import("/usr/src/app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-notifications___de",
    path: "notifications",
    component: () => import("/usr/src/app/pages/user/notifications.vue").then(m => m.default || m)
  },
  {
    name: "user-settings___de",
    path: "settings",
    component: () => import("/usr/src/app/pages/user/settings.vue").then(m => m.default || m)
  },
  {
    name: "user-wallet___de",
    path: "wallet",
    component: () => import("/usr/src/app/pages/user/wallet.vue").then(m => m.default || m)
  }
]
  },
  {
    name: userYgr9W7cUghMeta?.name,
    path: "/fr/user",
    meta: userYgr9W7cUghMeta || {},
    component: () => import("/usr/src/app/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: "user___fr",
    path: "",
    component: () => import("/usr/src/app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-notifications___fr",
    path: "notifications",
    component: () => import("/usr/src/app/pages/user/notifications.vue").then(m => m.default || m)
  },
  {
    name: "user-settings___fr",
    path: "settings",
    component: () => import("/usr/src/app/pages/user/settings.vue").then(m => m.default || m)
  },
  {
    name: "user-wallet___fr",
    path: "wallet",
    component: () => import("/usr/src/app/pages/user/wallet.vue").then(m => m.default || m)
  }
]
  },
  {
    name: userYgr9W7cUghMeta?.name,
    path: "/ru/user",
    meta: userYgr9W7cUghMeta || {},
    component: () => import("/usr/src/app/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: "user___ru",
    path: "",
    component: () => import("/usr/src/app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-notifications___ru",
    path: "notifications",
    component: () => import("/usr/src/app/pages/user/notifications.vue").then(m => m.default || m)
  },
  {
    name: "user-settings___ru",
    path: "settings",
    component: () => import("/usr/src/app/pages/user/settings.vue").then(m => m.default || m)
  },
  {
    name: "user-wallet___ru",
    path: "wallet",
    component: () => import("/usr/src/app/pages/user/wallet.vue").then(m => m.default || m)
  }
]
  },
  {
    name: userYgr9W7cUghMeta?.name,
    path: "/es/user",
    meta: userYgr9W7cUghMeta || {},
    component: () => import("/usr/src/app/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: "user___es",
    path: "",
    component: () => import("/usr/src/app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-notifications___es",
    path: "notifications",
    component: () => import("/usr/src/app/pages/user/notifications.vue").then(m => m.default || m)
  },
  {
    name: "user-settings___es",
    path: "settings",
    component: () => import("/usr/src/app/pages/user/settings.vue").then(m => m.default || m)
  },
  {
    name: "user-wallet___es",
    path: "wallet",
    component: () => import("/usr/src/app/pages/user/wallet.vue").then(m => m.default || m)
  }
]
  },
  {
    name: userYgr9W7cUghMeta?.name,
    path: "/cn/user",
    meta: userYgr9W7cUghMeta || {},
    component: () => import("/usr/src/app/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: "user___cn",
    path: "",
    component: () => import("/usr/src/app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-notifications___cn",
    path: "notifications",
    component: () => import("/usr/src/app/pages/user/notifications.vue").then(m => m.default || m)
  },
  {
    name: "user-settings___cn",
    path: "settings",
    component: () => import("/usr/src/app/pages/user/settings.vue").then(m => m.default || m)
  },
  {
    name: "user-wallet___cn",
    path: "wallet",
    component: () => import("/usr/src/app/pages/user/wallet.vue").then(m => m.default || m)
  }
]
  },
  {
    name: userYgr9W7cUghMeta?.name,
    path: "/am/user",
    meta: userYgr9W7cUghMeta || {},
    component: () => import("/usr/src/app/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: "user___am",
    path: "",
    component: () => import("/usr/src/app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-notifications___am",
    path: "notifications",
    component: () => import("/usr/src/app/pages/user/notifications.vue").then(m => m.default || m)
  },
  {
    name: "user-settings___am",
    path: "settings",
    component: () => import("/usr/src/app/pages/user/settings.vue").then(m => m.default || m)
  },
  {
    name: "user-wallet___am",
    path: "wallet",
    component: () => import("/usr/src/app/pages/user/wallet.vue").then(m => m.default || m)
  }
]
  }
]